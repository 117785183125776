import React from 'react'

const ScrollUp = () => {
    return (
        <div className="td_scrollup">
            <i className="fa-solid fa-arrow-up"></i>
        </div>
    )
}

export default ScrollUp
