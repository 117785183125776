import React from 'react'

const GetATourButton = () => {
    return (
        <div className="td_hero_toolbox_wrap" style={{cursor: "pointer"}}>
            <a className="td_btn td_style_1 td_radius_30 td_medium">
                <span className="td_btn_in td_white_color td_accent_bg">
                    <span> Enquire Now </span>
                </span>
            </a>
        </div>
    )
}

export default GetATourButton
