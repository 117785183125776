import React from 'react'

const Footer = () => {
    return (
        <footer className="td_footer td_style_1 td_type_1 td_color_1 bg-color">
            <div className="container">
                <div className="td_footer_row">
                    <div className="td_footer_col">
                        <div className="td_footer_widget">
                            <div className="td_footer_text_widget td_fs_18">
                                <img src="assets/img/home_4/logo/young-leaners.png" alt="Logo" width={80} height={200} />
                                <p>Far far away, behind the word mountains, far from the Consonantia, there live the blind texts.</p>
                            </div>
                            <div className="td_footer_social_btns td_fs_20">
                                <a href="#" className="td_center">
                                    <i className="fa-brands fa-facebook-f"></i>
                                </a>
                                <a href="#" className="td_center">
                                    <i className="fa-brands fa-x-twitter"></i>
                                </a>
                                <a href="#" className="td_center">
                                    <i className="fa-brands fa-instagram"></i>
                                </a>
                                <a href="#" className="td_center">
                                    <i className="fa-brands fa-pinterest-p"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="td_footer_col">
                        <div className="td_footer_widget">
                            <h2 className="td_footer_widget_title td_fs_32 td_white_color td_medium td_mb_30">Navigate</h2>
                            <ul className="td_footer_widget_menu">
                               
                                <li><a href="#">Gallery</a></li>
                                <li><a href="#">Resource</a></li>
                                <li><a href="/contact-us">Contact</a></li>
                                <li><a href="#">Enquiry </a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="td_footer_col">
                        <div className="td_footer_widget">
                            <h2 className="td_footer_widget_title td_fs_32 td_white_color td_medium td_mb_30">Programs</h2>
                            <ul className="td_footer_widget_menu">
                                <li><a href="/infant-program">Infant Program </a></li>
                                <li><a href="/toddler-program">Toddler Program</a></li>
                                <li><a href="/primary-program">Primary Program</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="td_footer_col">
                        <div className="td_footer_widget">
                            <h2 className="td_footer_widget_title td_fs_32 td_white_color td_medium td_mb_30">Subscribe Now</h2>
                            <ul className="td_footer_address_widget td_medium td_mp_0">
                                <li><i className="fa-solid fa-phone-volume"></i><a href="cal:562-202-4286">562-202-4286</a></li>
                                <li><i className="fa-solid fa-envelope"></i><a href="mailto:info@younglearnersartesia.com">info@younglearnersartesia.com</a></li>
                                <li><i className="fa-solid fa-location-dot"></i>18523, Arline Avenue, Artesia CA 90701. USA
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="td_footer_bottom td_fs_18">
                <div className="container">
                    <div className="td_footer_bottom_in">
                    
                        <p className="td_copyright mb-0">Copyright 2025 ©Young Learners | All Right Reserved</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
