import React from 'react'

const Loader = () => {
    return (
        <div className="td_preloader">
            <div className="td_preloader_in">
                <span></span>
                <span></span>
            </div>
        </div>
    )
}

export default Loader
