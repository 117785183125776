import React from 'react'

const Services = () => {
    return (
        <section>
            <div className="td_height_112 td_height_lg_75"></div>
            <div className="container">
                <div className="td_section_heading td_style_1 text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
                    <p className="td_section_subtitle_up td_fs_18 td_semibold td_spacing_1 td_mb_10 text-uppercase td_accent_color">WE CHANGE YOUR LIFE & WORLD</p>
                    <h2 className="td_section_title td_fs_48 mb-0">We’re Dedicated To Excellent <br />Service And Kids Care</h2>
                </div>
                <div className="td_height_50 td_height_lg_50"></div>
                <div className="row td_gap_y_24">
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                        <div className="td_team td_style_3 text-center position-relative">
                            <div className="td_team_thumb_wrap td_mb_20">
                                <div className="td_team_thumb">
                                    <img src="assets/img/home_4/team_member_5.jpg" alt="" className="w-100 td_radius_10" />
                                </div>
                                <img className="td_team_thumb_shape" src="assets/img/home_4/team_shape.png" alt="" />
                            </div>
                            <div className="td_team_info td_white_bg">
                                <h3 className="td_team_member_title td_fs_24 td_semibold mb-0">Oshana Alexa</h3>
                                <p className="td_team_member_designation mb-0 td_fs_18 td_opacity_7 td_heading_color">English teacher</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.35s">
                        <div className="td_team td_style_3 text-center position-relative">
                            <div className="td_team_thumb_wrap td_mb_20">
                                <div className="td_team_thumb">
                                    <img src="assets/img/home_4/team_member_2.jpg" alt="" className="w-100 td_radius_10" />
                                </div>
                                <img className="td_team_thumb_shape" src="assets/img/home_4/team_shape.png" alt="" />
                            </div>
                            <div className="td_team_info td_white_bg">
                                <h3 className="td_team_member_title td_fs_24 td_semibold mb-0">Yohana Alexa</h3>
                                <p className="td_team_member_designation mb-0 td_fs_18 td_opacity_7 td_heading_color">English teacher</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.4s">
                        <div className="td_team td_style_3 text-center position-relative">
                            <div className="td_team_thumb_wrap td_mb_20">
                                <div className="td_team_thumb">
                                    <img src="assets/img/home_4/team_member_6.jpg" alt="" className="w-100 td_radius_10" />
                                </div>
                                <img className="td_team_thumb_shape" src="assets/img/home_4/team_shape.png" alt="" />
                            </div>
                            <div className="td_team_info td_white_bg">
                                <h3 className="td_team_member_title td_fs_24 td_semibold mb-0">Mr. Lucas Johans</h3>
                                <p className="td_team_member_designation mb-0 td_fs_18 td_opacity_7 td_heading_color">English teacher</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.45s">
                        <div className="td_team td_style_3 text-center position-relative">
                            <div className="td_team_thumb_wrap td_mb_20">
                                <div className="td_team_thumb">
                                    <img src="assets/img/home_4/team_member_4.jpg" alt="" className="w-100 td_radius_10" />
                                </div>
                                <img className="td_team_thumb_shape" src="assets/img/home_4/team_shape.png" alt="" />
                            </div>
                            <div className="td_team_info td_white_bg">
                                <h3 className="td_team_member_title td_fs_24 td_semibold mb-0">Mary D Kuaesa</h3>
                                <p className="td_team_member_designation mb-0 td_fs_18 td_opacity_7 td_heading_color">English teacher</p>
                            </div>
                        </div>
                    </div>
                </div>
               
                
            </div>
            <div className="mb-5"></div>
        </section>
    )
}

export default Services
