import React from 'react'

const Hero = () => {
    return (
        <section className="td_hero td_style_4 td_center text-center td_hobble">
            <div className="container">
                <div className="td_hero_text wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                    <p className="td_hero_subtitle_up td_accent_color text-uppercase td_semibold td_fs_18 td_mb_10 td_opacity_9 td_spacing_1">KINDERGARDEN & BABY CARE</p>
                    <h1 className="td_hero_title td_fs_64 td_mb_20">We Prepare Your Child For Lifetime</h1>
                    <p className="td_hero_subtitle td_fs_18 td_heading_color text-capitalize td_mb_40">We denounce with righteous indignation and dislike men who are so beguiled and demoralized</p>
                    <div className="td_btns_group">
                        <a href="#" className="td_btn td_style_1 td_radius_30 td_medium">
                            <span className="td_btn_in td_white_color td_accent_bg">
                                <span>Read More</span>
                                <svg strokestrokestrokewidth="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.1575 4.34302L3.84375 15.6567" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M15.157 11.4142C15.157 11.4142 16.0887 5.2748 15.157 4.34311C14.2253 3.41142 8.08594 4.34314 8.08594 4.34314" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </span>
                        </a>
                       
                    </div>
                </div>
            </div>
            <div className="td_hero_img_box_left">
                <div className="td_hero_img_1 position-absolute wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.35s">
                    <div className="td_hero_img_in">
                        <img src="assets/img/home_4/hero_img_1.png" alt="" />
                    </div>
                </div>
                <div className="td_hero_img_2 position-absolute wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.35s">
                    <div className="td_hero_img_in">
                        <img src="assets/img/home_4/hero_img_2.png" alt="" />
                    </div>
                </div>
                <div className="td_hero_shape_1 position-absolute td_hover_layer_5">
                    <img src="assets/img/home_4/hero_shape_1.svg" alt="" />
                </div>
                <div className="td_hero_shape_2 position-absolute td_hover_layer_3">
                    <img src="assets/img/home_4/hero_shape_2.svg" alt="" />
                </div>
                <div className="td_hero_shape_5 position-absolute">
                    <img src="assets/img/home_4/hero_shape_5.svg" alt="" />
                </div>
            </div>
            <div className="td_hero_img_box_right">
                <div className="td_hero_img_3 position-absolute wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.35s">
                    <div className="td_hero_img_in">
                        <img src="assets/img/home_4/hero_img_3.png" alt="" />
                    </div>
                </div>
                <div className="td_hero_img_4 position-absolute wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.35s">
                    <div className="td_hero_img_in">
                        <img src="assets/img/home_4/hero_img_4.png" alt="" />
                    </div>
                </div>
                <div className="td_hero_shape_3 position-absolute td_hover_layer_5">
                    <img src="assets/img/home_4/hero_shape_3.svg" alt="" />
                </div>
                <div className="td_hero_shape_4 position-absolute td_hover_layer_3">
                    <img src="assets/img/home_4/hero_shape_4.svg" alt="" />
                </div>
            </div>
            <div className="td_hero_shape_6 position-absolute td_hover_layer_3">
                <img src="assets/img/home_4/hero_shape_6.svg" alt="" />
            </div>
            <div className="td_hero_shape_7 position-absolute">
                <img src="assets/img/home_4/hero_shape_7.svg" alt="" />
            </div>
            <div className="td_hero_shape_8 position-absolute td_hover_layer_3">
                <img src="assets/img/home_4/hero_shape_8.svg" alt="" />
            </div>
            <div className="td_hero_shape_9 position-absolute">
                <img src="assets/img/home_4/hero_shape_9.svg" alt="" />
            </div>
            <div className="td_hero_shape_10 position-absolute td_hover_layer_5">
                <img src="assets/img/home_4/hero_shape_10.svg" alt="" />
            </div>
        </section>
    )
}

export default Hero
