import React from 'react'

const Logo = () => {
    return (
        <div className="td_main_header_left">
            <div className="td_site_branding" href="/">

                <a href='/'>    <img className='mbl-logo' src="assets/img/home_4/logo/young-leaners.png" alt="Logo" width={88} height={200} /></a>
            </div>
        </div>
    )
}

export default Logo