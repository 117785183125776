import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Index from './pages';
import { EnrichmentClasses } from './pages/EnrichmentClasses';
import InfantProgram from './pages/InfantProgram'
import ToddlerProgram from './pages/ToddlerProgram';
import PrimaryProgram from './pages/PrimaryProgram'
import ContactUS from './pages/ContactUS';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/enrichment-classes" element={<EnrichmentClasses />} />
          <Route path="/infant-program" element={<InfantProgram />} />
          <Route path="/toddler-program" element={<ToddlerProgram />} />
          <Route path="/primary-program" element={<PrimaryProgram />} />
          <Route path="/contact-us" element={<ContactUS />} />
          
        </Routes>
      </div>
    </Router>
  );
}

export default App;