import React from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import ScrollUp from '../components/scrollup/ScrollUp'
import { Helmet } from 'react-helmet';

const ToddlerProgram = () => {
    return (
        <>
            <Helmet>
                <title>Toddler Program (18–36 months) | Young Learners' Montessori</title>
                <meta name="description" content="Toddler Program (18–36 months) , Young Learners' Montessori" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <Header />
            <section
                className="td_height td_center td_bg_filed td_heading_bg text-center td_hobble td_left ">
                <h2 className='color'>Toddler Program (18–36 months)</h2>
            </section>
            <section className='p-5'>

                <div className='img-p-section-css d-flex'>
                    <div className='img-div'>
                        <img className='image' alt="" src="assets/images/pages-images/toddler-programe.png" />
                    </div>
                    <div className='content mt-3' >
                        <p>At Young Learners’ Montessori, our Toddler Program is thoughtfully designed to <span className='span'>foster curiosity, independence, and foundational skills</span> in a <span className='span'>warm, nurturing environment.</span> The toddler years are a period of <span className='span'>rapid cognitive, social, and physical development</span>, and our carefully prepared classrooms encourage <span className='span'>exploration while providing a structured space for meaningful learning.</span> </p>
                        <h2 className='h2-tag  mt-4 mb-3'>What Makes Our Toddler Program Special?</h2>
                        <h3 className="h3-tag remove-padding mb-2">Freedom Within Limits</h3>
                        <p className='remove-padding'>Toddlers thrive when they have the ability to explore within a structured and safe environment. Our Montessori approach offers:</p>
                        <ul className='margin-left'>
                            <li><span className='span'>Hands-on learning experiences </span> that promote independence.</li>
                            <li><span className='span'> Age-appropriate materials </span> to support fine and gross motor development.</li>
                            <li><span className='span'> A structured routine </span> that balances freedom with gentle guidance from trained Montessori educators.</li>
                        </ul>
                    </div>
                </div>

                <h2 className='h2-tag mt-3'>A Prepared Montessori Environment</h2>
                <p className='mt-1'>Our toddler classrooms are designed with young children in mind. Every detail, from <span className='span'>child-sized furniture to engaging activities,</span> supports self-discovery and confidence-building. Practical life activities, such as pouring, dressing, and cleaning up, encourage self-sufficiency and responsibility.</p>

                <h2 className='h2-tag mb-1'>Developing Social and Emotional Awareness</h2>
                <p className='remove-padding'>Social-emotional learning is at the heart of our Toddler Program. Our teachers model <span className='span'>respectful behavior </span>, encourage self-expression, and guide children in:</p>
                <ul className='margin-left'>
                    <li>Learning how to <span className='span'>share, take turns, and communicate their feelings.</span> </li>
                    <li>Practicing <span className='span'>cooperation</span> through group activities and peer interaction.</li>
                    <li>Building <span className='span'>self-confidence</span> by accomplishing tasks independently.</li>
                </ul>

                <h2 className='h2-tag mb-1'>Encouraging Language Development</h2>
                <p className='remove-padding'>The toddler stage is a crucial period for language acquisition. We support verbal development by:</p>
                <ul className='margin-left'>
                    <li><span className='span'>Engaging toddlers in conversations, storytelling, and music.</span></li>
                    <li>Introducing a <span className='span'>rich vocabulary</span> through books and interactive discussions.</li>
                    <li>Encouraging <span className='span'>sign language or simple words</span> for early communication.</li>
                </ul>

                <p >At Young Learners’ Montessori, we recognize that every toddler is unique, and we honor their individuality by providing a <span className='span'>loving, stimulating environment</span> where they can learn, grow, and thrive at their own pace.</p>

            </section >
            <Footer />
            <ScrollUp />

        </>
    )
}

export default ToddlerProgram
