import React from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import ScrollUp from '../components/scrollup/ScrollUp'
import { Helmet } from 'react-helmet'; 
export const EnrichmentClasses = () => {
    return (
        <>
            <Helmet>
                <title>Enrichment Classes | Young Learners' Montessori</title>
                <meta name="description" content="Enrichment Classes , Young Learners' Montessori" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <Header />
            <section
                className="td_height td_center td_bg_filed td_heading_bg text-center td_hobble td_left">
                <h2 className='color'>Enrichment Classes</h2>
            </section>

            <section className='p-5'>
                <p>At Young Learners’ Montessori, we believe in the power of a well-rounded education that goes beyond core academics. Our Enrichment Classes are designed to introduce children to a variety of experiences that support their development in unique and essential ways. These activities foster creativity, physical health, emotional well-being, and cultural awareness—all of which play a critical role in a child’s growth.
                </p>

                <h2 className='h2-tag mt-5'>Why Exposing Children to Enrichment Classes is Essential for Their Development</h2>
                <p>As children grow, it is important to provide them with a wide range of activities that stimulate both their minds and bodies. At this stage, their brains are incredibly receptive to new experiences, and engaging them in activities outside of traditional academics helps promote cognitive flexibility, social skills, and a sense of self. Here’s why exposing your child to our enrichment classes is not just fun but a vital part of their development:
                </p>

                <div className="container">
                    <div className="row td_row_reverse_lg td_gap_y_50">
                        <div className="col-lg-12">
                            <div className="row td_gap_y_30">
                                <div className="col-lg-4">
                                    <div className="td_post td_style_1">
                                        <div className="td_post_info" style={{ borderTopWidth: "1px", textAlign: "center", borderRadius: "20px", height: "575px" }}>
                                            <a className="d-block">
                                                <img alt="" src="assets/images/enrichment/physical-fitness.png" style={{ width: "60%" }} />
                                            </a>
                                            <h3 style={{ fontSize: "27px", paddingTop: "25px", margin: "0px" }}>Physical Fitness</h3>
                                            <p className="td_post_subtitle td_mb_24 td_heading_color td_opacity_7">
                                                Physical activity is essential for young children, and our Physical Fitness class helps improve coordination, strength, and flexibility while building lifelong healthy habits. Regular exercise helps children develop motor skills, improve concentration, and regulate emotions. By encouraging physical fitness early on, children learn the importance of staying active for both their physical and mental well-being.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="td_post td_style_1">
                                        <div className="td_post_info" style={{ borderTopWidth: "1px", textAlign: "center", borderRadius: "20px", height: "575px" }}>
                                            <a className="d-block">
                                                <img alt="" src="assets/images/enrichment/art.jpg" style={{ width: "60%" }} />
                                            </a>
                                            <h3 style={{ fontSize: "27px", paddingTop: "25px", margin: "0px" }}>Art</h3>
                                            <p className="td_post_subtitle td_mb_24 td_heading_color td_opacity_7">
                                                The creative process is an important part of early childhood development. Through our Art classes, children are introduced to various mediums and techniques, allowing them to express their ideas and emotions in a safe and non-judgmental environment. Art encourages fine motor development, boosts creativity, and enhances problem-solving skills, all while fostering a sense of pride in their work.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="td_post td_style_1">
                                        <div className="td_post_info" style={{ borderTopWidth: "1px", textAlign: "center", borderRadius: "20px", height: "575px" }}>
                                            <a className="d-block">
                                                <img alt="" src="assets/images/enrichment/music.png" style={{ width: "60%" }} />
                                            </a>
                                            <h3 style={{ fontSize: "27px", paddingTop: "25px", margin: "0px" }}>Music</h3>
                                            <p className="td_post_subtitle td_mb_24 td_heading_color td_opacity_7">
                                                Music is a powerful tool for enhancing cognitive and emotional development. In our Music class, children explore rhythm, melody, and movement, which helps with language development, memory retention, and pattern recognition. Music also nurtures a child’s creativity and self-expression, while teaching them important social skills such as listening, cooperation, and taking turns.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="td_post td_style_1">
                                        <div className="td_post_info" style={{ borderTopWidth: "1px", textAlign: "center", borderRadius: "20px", height: "575px" }}>
                                            <a className="d-block">
                                                <img alt="" src="assets/images/enrichment/hindi-language.png" style={{ width: "60%" }} />
                                            </a>
                                            <h3 style={{ fontSize: "27px", paddingTop: "25px", margin: "0px" }}>Hindi Language</h3>
                                            <p className="td_post_subtitle td_mb_24 td_heading_color td_opacity_7">
                                                Language acquisition is most effective at an early age, and learning a second language opens up a world of opportunities. Our Hindi Language class provides children with the chance to explore a new culture while building their vocabulary and language skills. Early exposure to languages enhances cognitive flexibility, improves communication, and increases cultural awareness—a key skill in today’s interconnected world.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="td_post td_style_1">
                                        <div className="td_post_info" style={{ borderTopWidth: "1px", textAlign: "center", borderRadius: "20px", height: "575px" }}>
                                            <a className="d-block">
                                                <img alt="" src="assets/images/enrichment/gardening.png" style={{ width: "60%" }} />
                                            </a>
                                            <h3 style={{ fontSize: "27px", paddingTop: "25px", margin: "0px" }}>Gardening</h3>
                                            <p className="td_post_subtitle td_mb_24 td_heading_color td_opacity_7">
                                                Gardening offers children a hands-on way to connect with nature while learning valuable lessons in responsibility and patience. As they plant, water, and care for their garden, children develop an understanding of biology and the importance of nurturing living things. Gardening also promotes teamwork, fine motor skills, and a sense of accomplishment as children see the fruits of their labor grow.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="td_post td_style_1">
                                        <div className="td_post_info" style={{ borderTopWidth: "1px", textAlign: "center", borderRadius: "20px", height: "575px" }}>
                                            <a className="d-block">
                                                <img alt="" src="assets/images/enrichment/yoga.png" style={{ width: "60%" }} />
                                            </a>
                                            <h3 style={{ fontSize: "27px", paddingTop: "25px", margin: "0px" }}>Yoga</h3>
                                            <p className="td_post_subtitle td_mb_24 td_heading_color td_opacity_7">
                                                Yoga is an excellent way to promote mindfulness, balance, and self-regulation. By learning yoga techniques, children develop better focus, relaxation, and body awareness, which supports their emotional growth and self-control. Yoga also helps children learn how to manage stress, improve posture, and cultivate a sense of calm that benefits their overall health.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div className="mt-4"></div>
                <h2 className='h2-tag mt-5'>A Comprehensive Approach to Childhood Development</h2>
                <p> Incorporating these enrichment activities into your child’s routine provides them with a well-rounded educational experience that enhances their academic skills, physical health, emotional resilience, and creativity. These classes are not just extracurricular—they are an essential part of developing the whole child. The early years are a crucial time for shaping your child’s future, and these enrichment experiences lay the groundwork for a lifetime of learning, well-being, and success.At Young Learners’ Montessori, we are committed to providing children with the best opportunities to thrive. We believe that these enrichment classes are not just an addition to their education—they are integral to developing confident, well-rounded individuals ready to take on the world.</p>
            </section>
            <Footer />
            <ScrollUp />

        </>
    )
}