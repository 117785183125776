import React from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import ScrollUp from '../components/scrollup/ScrollUp'
import { Helmet } from 'react-helmet'; 

const PrimaryProgram = () => {
  return (
    <>
      <Helmet>
          <title>Primary Program (3–6 years) | Young Learners' Montessori</title>
          <meta name="description" content="Primary Program (3–6 years), Young Learners' Montessori" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Header />
      <section
        className="td_height td_center td_bg_filed td_heading_bg text-center td_hobble td_left ">
        <h1 className='color'>Primary Program (3–6 years) </h1>
      </section>
      <section className='p-5'>
        <p>Our Primary Program provides a dynamic and engaging learning environment for children ages 3 to 6, where independence, critical thinking, and creativity are encouraged in every activity. This is a time when children are building the foundation for lifelong learning, and we create a classroom experience that nurtures their natural curiosity while developing essential academic and social skills.
        </p>
        <h2 className='h2-tag mb-2 mt-4'>What Makes Our Primary Program Special?</h2>
        <p className=" remove-padding">Montessori-Inspired Learning</p>
        <p className='remove-padding'>The Montessori method is at the core of our Primary Program, emphasizing hands-on, individualized learning. Children explore a wide range of subjects through carefully selected Montessori materials that promote self-discovery, concentration, and problem-solving.</p>

        <h2 className='h2-tag mt-4'>Key Learning Areas</h2>
        <div className="container mt-4">
          <div className="row td_row_reverse_lg td_gap_y_50">
            <div className="col-lg-12">
              <div className="row td_gap_y_30">
                <div className="col-lg-4">
                  <div className="td_post td_style_1">
                    <div className="td_post_info key-learing-border set-height" >
                      <a className="d-block">
                          <img alt="" src="assets/images/programs/practical-life.png" style={{ width: "60%" }} />
                      </a>

                      <h3 className='h3-tag primary'>Practical Life</h3>
                      <p className="td_post_subtitle td_mb_24 td_heading_color td_opacity_7">
                        Our students learn essential life skills such as dressing themselves, preparing snacks, and cleaning up. These activities build fine motor skills, improve coordination, and foster a sense of responsibility as children learn to take care of their space. As our students master these tasks, they gain pride and joy in their accomplishments.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="td_post td_style_1">
                    <div className="td_post_info key-learing-border set-height" >
                      <a className="d-block">
                          <img alt="" src="assets/images/programs/sensorial.png" style={{ width: "60%" }} />
                      </a>

                      <h3 className='h3-tag primary'>Sensorial</h3>
                      <p className="td_post_subtitle td_mb_24 td_heading_color td_opacity_7">
                        Sensorial exercises help our students fine-tune their senses, teaching them to observe and organize the world around them. Activities like sorting objects by size and matching colors enhance their ability to notice details and recognize patterns in their environment.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="td_post td_style_1">
                    <div className="td_post_info key-learing-border set-height" >
                      <a className="d-block">
                          <img alt="" src="assets/images/programs/language.png" style={{ width: "60%" }} />
                      </a>
                      <h3 className='h3-tag primary'>Language</h3>
                      <p className="td_post_subtitle td_mb_24 td_heading_color td_opacity_7">
                        Children begin learning to write and read at the ideal age for language development, and they do so with excitement. With hands-on Montessori materials like the Sandpaper Letters, students learn the shapes and sounds of letters. Many children who join us at age three are reading by age six. Instruction is tailored to each child’s level, ensuring that every student develops their language skills at their own pace.                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="td_post td_style_1">
                    <div className="td_post_info key-learing-border " >
                        <a className="d-block">
                            <img alt="" src="assets/images/programs/math.png" style={{ width: "35%" }} />
                        </a>
                      <h3 className='h3-tag primary'>Math</h3>
                      <p className="td_post_subtitle td_mb_24 td_heading_color td_opacity_7">
                        In our Primary Program, math extends beyond simple counting. Students use hands-on materials to explore addition, subtraction, multiplication, and division, gaining confidence as they work with numbers that extend far beyond typical preschool standards. Our colorful, visual approach makes learning math an enjoyable experience, fostering both understanding and love for numbers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="td_post td_style_1">
                    <div className="td_post_info key-learing-border" >
                        <a className="d-block">
                            <img alt="" src="assets/images/programs/cultural-studies-and-geography.jpg" style={{ width: "35%" }} />
                        </a>
                      <h3 className='h3-tag primary'>Cultural Studies and Geography</h3>
                      <p className="td_post_subtitle td_mb_24 td_heading_color td_opacity_7">
                        Our students are immersed in cultural studies, including botany, geography, and history. We focus on building vocabulary and comprehension at a time when young minds are especially receptive, or as Dr. Montessori called it, the "Absorbent Mind." This broad knowledge base helps our students excel in school and life, promoting a deeper understanding of the world around them.                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 className='h2-tag mt-5 mb-3'>Social and Emotional Growth</h2>
        <img className="mb-3" alt="" src="assets/images/programs/social-demo.jpg" style={{width: '100%', height: 200 }}/>
        <p className='mt-1'>The Primary Program also nurtures emotional intelligence and social skills. Through group activities, cooperative learning, and discussions, children practice respect, empathy, and collaboration. Teachers guide them in managing emotions, building self-confidence, and resolving conflicts in positive, constructive ways.</p>
        <h2 className='h2-tag mb-3'>Creativity and Exploration</h2>
        <img className="mb-3" alt="" src="assets/images/programs/creativity-demo.jpg" style={{width: '100%', height: 200 }}/>
        <p className='mt-1'>Creative expression is encouraged in the Primary Program. Children explore art, music, dance, and imaginative play, developing confidence in their ability to express ideas and emotions through various media. These activities help nurture creativity, fine motor skills, and a love for artistic expression.</p>
        <p>At Young Learners' Montessori, we provide a balanced approach to learning where children can discover their strengths, make choices, and develop into curious, confident, and independent learners. Our Primary Program is a place where children build a solid academic foundation while developing important life skills and a lifelong love of learning.</p>
      </section>
      <Footer />
      <ScrollUp />

    </>
  )
}

export default PrimaryProgram
