import React from 'react'

const AcademicCourses = () => {
    return (
        <section className="td_gray_bg_7 td_shape_section_3 ">
            <div className="td_shape td_shape_position_1">
                <img src="assets/img/home_4/course_shape_1.svg" alt="" />
            </div>
            <div className="td_shape td_shape_position_2">
                <img src="assets/img/home_4/course_shape_2.svg" alt="" />
            </div>
            <div className="td_shape td_shape_position_3">
                <img src="assets/img/home_4/course_shape_3.svg" alt="" />
            </div>
            <div className="td_shape td_shape_position_4"></div>
            <div className="td_height_112 td_height_lg_75"></div>
            <div className="container">
                <div className="td_section_heading td_style_1 text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
                    
                    <h2 className="td_section_title td_fs_48 mb-0 h2_color">Programs</h2>
                </div>
                <div className="td_height_50 td_height_lg_50"></div>
                <div className="row td_gap_y_30">
                    <div className="col-lg-4 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.25s">
                        <div className="td_card td_style_3 td_type_1 d-block td_radius_10">
                            <a href="/infant-program" className="td_card_thumb">
                                <img src="assets/img/home_4/course_thumb_1.jpg" alt="" />
                            </a>
                            <div className="td_card_info">
                                <div className="td_card_info_in">
                                   
                                    <h2 className="td_card_title td_fs_24 td_mb_16"><a href="/infant-program" className='programs'>Infant Program</a></h2>
                                    <p className="td_card_subtitle td_heading_color td_opacity_7 td_mb_20">Our Infant Program provides a warm, nurturing environment where your baby can feel safe, secure, and supported in their early development.</p>
                                    <a href="/infant-program" className="td_card_category td_fs_14 td_semibold td_heading_color td_mb_14 programs"><span>Read More</span></a>

                                    {/* <div className="td_card_btn">
                                        <a href="#" className="td_btn td_style_1 td_radius_30 td_medium">
                                            <span className="td_btn_in td_white_color td_accent_bg">
                                                <span>Enroll Now</span>
                                                <svg strokestrokewidth="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.1575 4.34302L3.84375 15.6567" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    <path d="M15.157 11.4142C15.157 11.4142 16.0887 5.2748 15.157 4.34311C14.2253 3.41142 8.08594 4.34314 8.08594 4.34314" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                </svg>
                                            </span>
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                        <div className="td_card td_style_3 td_type_1 d-block td_radius_10">
                            <a href="/toddler-program" className="td_card_thumb">
                                <img src="assets/img/home_4/course_thumb_2.jpg" alt="" />
                            </a>
                            <div className="td_card_info">
                                <div className="td_card_info_in">
                                    <h2 className="td_card_title td_fs_24 td_mb_16"><a href="/toddler-program" className='programs'>Toddler Program</a></h2>
                                    <p className="td_card_subtitle td_heading_color td_opacity_7 td_mb_20">At Young Learners’ Montessori, our Toddler Program is thoughtfully designed to foster curiosity, independence, and foundational skills in a warm, nurturing environment.</p>
                                    <a href="/toddler-program" className="td_card_category td_fs_14 td_semibold td_heading_color td_mb_14 programs"><span>Read More</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.35s">
                        <div className="td_card td_style_3 td_type_1 d-block td_radius_10">
                            <a href="/primary-program" className="td_card_thumb">
                                <img src="assets/img/home_4/course_thumb_3.jpg" alt="" />
                            </a>
                            <div className="td_card_info">
                                <div className="td_card_info_in">
                                    <h2 className="td_card_title td_fs_24 td_mb_16"><a href="/primary-program" className='programs'>Primary Program</a></h2>
                                    <p className="td_card_subtitle td_heading_color td_opacity_7 td_mb_20">Our Primary Program provides a dynamic and engaging learning environment for children ages 3 to 6, where independence, critical thinking, and creativity are encouraged in every activity. </p>
                                    <a href="/primary-program" className="td_card_category td_fs_14 td_semibold td_heading_color td_mb_14 programs"><span>Read More</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="td_height_50 td_height_lg_40"></div>
                <div className="text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                    <a href="/infant-program" className="td_btn td_style_3 td_medium td_heading_color td_fs_18">
                        <span className='h2_color'>See all Programs</span>
                        <i className="h2_color">
                            <svg strokestrokewidth="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17 6L1 6" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M12 11C12 11 17 7.31756 17 5.99996C17 4.68237 12 1 12 1" stroke="currentColor" stroke-strokestrokewidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </i>
                    </a>
                </div>
            </div>
            <div className="td_height_120 td_height_lg_80"></div>
        </section>
    )
}

export default AcademicCourses
