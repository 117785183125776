import React from 'react'
import Logo from './Logo'
import Menu from './Menu'
import GetATourButton from './GetATourButton'

const Header = () => {
    return (
        <header className="td_site_header td_style_1 td_type_1 td_sticky_header td_medium td_heading_color">
            <div className="td_main_header">
                <div className="container">
                    <div className="td_main_header_in">
                        <Logo />
                        <div className="td_main_header_right">
                            <Menu />
                            <GetATourButton />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
