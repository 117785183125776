import React from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import ScrollUp from '../components/scrollup/ScrollUp'
import { Helmet } from 'react-helmet';

const InfantProgram = () => {
  return (
    <>
      <Helmet>
        <title>Infant Program (6–18 months) | Young Learners' Montessori</title>
        <meta name="description" content="Infant Program (6–18 months) , Young Learners' Montessori" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Header />
      <section
        className="td_height td_center td_bg_filed td_heading_bg text-center td_hobble td_left">
        <h1 className='color'>Infant Program (6–18 months)</h1>
      </section>
      <section className='img-p-section-css p-5 d-flex'>
        <div className='img-div' >
          <img className='image' alt="" src="assets/images/pages-images/infant-programe.jpg"/>
        </div>
        <div className='content'>
          <p>Our Infant Program provides a <span className='span'> warm, nurturing environment </span> where your baby can feel <span className='span'>safe, secure, and supported</span> in their early development. We welcome children as young as six months old, ensuring their <span className='span'>individual needs</span> are met with the utmost care and attention.</p>
          <p>The first years of life are a <span className='span'>critical period for bonding, language acquisition, and movement.</span> Our thoughtfully designed spaces <span className='span'>encourage exploration, independence, and growth</span> through age-appropriate Montessori materials. Babies engage in <span className='span'>sensory experiences,</span> gentle movement activities, and early social interactions that lay the foundation for future learning.</p>
          <p>Our dedicated infant teachers work closely with families to ensure a smooth and comforting transition into our program. When you step into our infant classroom, you’ll find a caring and engaging space where your child is embraced with love, encouraged to explore, and given the foundation for a lifelong love of learning.</p>
        </div>

      </section>
      <Footer />
      <ScrollUp />

    </>
  )
}

export default InfantProgram
