import React from 'react'

const Menu = () => {
    return (
        <nav className="td_nav">
            <div className="td_nav_list_wrap">
                <div className="td_nav_list_wrap_in">
                    <ul className="td_nav_list">
                        <li className="menu-item-has-children menu-list">
                            <a href="#">Programs</a>
                            <ul className='bg'>
                                <li><a href="/infant-program">Infant Program(6–18 months) </a></li>
                                <li><a href="/toddler-program">Toddler Program(18–36 months)</a></li>
                                <li><a href="/primary-program">Primary Program(3–6 years)</a></li>
                            </ul>
                        </li>
                        <li className='menu-list'>
                            <a href="/enrichment-classes">Enrichment Classes</a>
                        </li>
                        <li className='menu-list'>
                            <a href="#" > Gallery
                            </a>
                        </li>
                        <li className='menu-list'><a href="#">Resource</a></li>
                        <li className='menu-list'>
                            <a href="/contact-us">Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Menu