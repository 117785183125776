import React from "react";
import Testimonials from '../components/testimonials/Testimonials';
import Header from "../components/header/Header";
import Hero from "../components/hero/Hero";
import AboutUsSection from '../components/about/AboutUsSection';
import AcademicCourses from "../components/courses/AcademicCourses";
import Services from "../components/services/Services";
import Footer from "../components/footer/Footer";
import ScrollUp from "../components/scrollup/ScrollUp";
import Loader from "../components/loader/Loader";

function Index() {
  return (
    <>
      <Loader />
      <Header />
      <Hero />
      <AboutUsSection />
      <AcademicCourses />
      <Testimonials />
      <Services />
      <Footer />
      <ScrollUp />
    </>
  );
}

export default Index;