import React from 'react'
import { Helmet } from 'react-helmet'; 
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import ScrollUp from '../components/scrollup/ScrollUp'

const ContactUS = () => {
    return (
        <>
            <Helmet>
                <title>Contact Us | Young Learners' Montessori</title>
                <meta name="description" content="Young Learners' Montessori" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>
            <Header />
            <section
                className="td_height td_center td_bg_filed td_heading_bg text-center td_hobble td_left ">
                <h2 className='color'>Contact Us</h2>
            </section>

            <section className='mt-5'>
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-10 offset-xxl-1">
                            <div className="row align-items-center td_gap_y_40">
                                <div className="col-lg-8">
                                    <div className="td_map">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1000!2d-118.0817!3d33.8636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd2d0834b8c7e5%3A0x9e8f8c8e8d8f8c8e!2s18523%20Arline%20Ave%2C%20Artesia%2C%20CA%2090701%2C%20USA!5e0!3m2!1sen!2sus!4v1741692659934!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div>
                                <div className="col-xl-4 offset-xl-1 col-lg-5" style={{ margin: "0px" }}>
                                    <div className="td_contact_info">
                                        <div className="td_section_heading td_style_2 td_mb_20">
                                            <h2 className="td_contact_info_title td_fs_36 mb-0">Our Address</h2>
                                        </div>
                                        <div className="td_mb_40">
                                            <h2 className="td_fs_24 td_semibold td_mb_20">USA Campus</h2>
                                            <p className="td_fs_18 td_heading_color td_medium td_mb_10">18523, Arline Avenue, Artesia CA 90701. USA</p>
                                            <p className="td_fs_18 td_heading_color td_medium td_mb_10 td_opacity_7">
                                            <a href="tel:562-202-4286" style={{ textDecoration: 'none', color: 'inherit' }}>562-202-4286</a>
                                            </p>
                                            <p className="td_fs_18 td_heading_color td_medium mb-0 td_opacity_7">
                                            <a href="mailto:info@younglearnersartesia.com" style={{ textDecoration: 'none', color: 'inherit' }}>info@younglearnersartesia.com</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="td_height_120 td_height_lg_80"></div>
            </section>
            <Footer />
            <ScrollUp />

        </>
    )
}

export default ContactUS
